import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import styled from "styled-components";

const FooterStyles = styled.footer`
    text-align: center;
    padding: 1rem;
    color: ${({ theme }) => theme.color.text};
`

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            site {
            siteMetadata {
                title
            }
            }
        }
    `)
    return (
    <FooterStyles>
        {data.site.siteMetadata.title}, {new Date().getFullYear()}, Alle rechten voorbehouden
    </FooterStyles>)
}