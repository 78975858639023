import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import WelcomeName from "./welcome-name";
import SignInSignOutButton from "./sign-in-sign-out-button";

const HeaderStyles = styled.header`
    nav ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 0 1fr 1fr 2fr 1fr;
        justify-content: space-between;
        @media (min-width: 768px) {
            grid-template-columns: 1fr 8fr 1fr 1fr min-content min-content;
        }
    }
    nav ul li a {
        display: inline-block;
        padding: 1rem;
        text-decoration: none;
        color: ${({ theme }) => theme.color.text};
    }
    /* default hover effect */
    nav ul li a:hover {
        background-color: ${({ theme }) => theme.color.background};
    }
    @media (hover: hover) {
    /* when hover is supported */
        nav ul li a:hover {
            background-color: ${({ theme }) => theme.color.secondary};
        }
    }
`

export default function Header() {
    return (
    <HeaderStyles>
        <nav>
            <ul>
                <li><Link to="/">ClinicBase</Link></li>
                <div></div>
                <li><Link to="/about">Over</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><WelcomeName></WelcomeName></li>
                <li><SignInSignOutButton></SignInSignOutButton></li>
            </ul>
        </nav>
    </HeaderStyles>)
}