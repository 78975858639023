import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";

const WelcomeNameStyles = styled.div`
    nav ul li a {
        display: inline-block;
        padding: 1rem;
        text-decoration: none;
        color: ${({ theme }) => theme.color.text};
    }
`

const WelcomeName = () => {
    const { accounts } = useMsal();
    const [name, setName] = useState(null);

    useEffect(() => {
        if (accounts.length > 0) {
            setName(accounts[0].name.split(" ")[0]);
        } else {
            setName(null);
        }
    }, [accounts]);

    if (name) {
        return <WelcomeNameStyles>Welkom, {name}</WelcomeNameStyles>;
    } else {
        return null;
    }
};

export default WelcomeName;