// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.GATSBY_CLIENT_ID,
        authority: process.env.GATSBY_AUTHORITY_URI,
        knownAuthorities: [process.env.GATSBY_AZURE_B2C_BASE_URL],
        redirectUri: process.env.GATSBY_REDIRECT_URI,
        postLogoutRedirectUri: process.env.GATSBY_WEB_APP_BASE_URL
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
        secureCookies: true
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [process.env.GATSBY_APPLICATION_ID_URI + "/user-data.read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v2.0/me"
};