import React from "react";
import Footer from "./footer";
import Header from "./header";
import styled from "styled-components";

const LayoutStyles = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
`

export default function Layout({ children }) {
    return (
        <LayoutStyles>
            <Header></Header>
            <main>{children}</main>
            <Footer></Footer>
        </LayoutStyles>
    )
}